import DashboardLayout from "@/pages/layouts/DashboardLayout";

let governorateRoutes = {
  path: '/governorates',
  component: DashboardLayout,
  redirect: '/governorates/list',
  children: [
    {
      path: 'list',
      name: 'Governorate List',
      component: () => import(/* webpackChunkName: "governorates" */  '@/pages/lebanonElectionResults/governorates/GovernorateList'),
      meta: {
        permissionsCodes: ['governorates/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Governorate Item',
      component: () => import(/* webpackChunkName: "governorates" */  '@/pages/lebanonElectionResults/governorates/GovernorateForm'),
      meta: {
        permissionsCodes: ['governorates/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Governorate Item',
      component: () => import(/* webpackChunkName: "governorates" */  '@/pages/lebanonElectionResults/governorates/GovernorateForm'),
      meta: {
        permissionsCodes: ['governorates/update'],
      }
    }
  ]
}

export default governorateRoutes;
