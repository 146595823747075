<template>
    <el-dialog
            v-if="poll"
            center
            @close="closeModal()"
            :visible="visible">
        <h3 style="text-align: center">{{poll.title }}</h3>

        <div class="searchable-container">
          <div class="question-row row" v-for="(question,questionIndex) in poll.questions" v-bind:key="questionIndex">
            <div class="col-6">
              <label>{{question.text}}</label>
            </div>
            <div class="col-6">
              <div class="row answers-container" v-for="(answer,answerIndex) in question.answers" v-bind:key="answerIndex">
                <p>{{answer.text}}</p>
                <p class="box-counter badge">counter: {{answer.counter}}</p>
                <p class="box-counter badge">percentage: {{answer.percentage + '%'}}</p>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
    </el-dialog>
</template>

<script>
    import {Dialog} from 'element-ui'

    export default {
        name: 'show-percentage-modal',

        components: {
            [Dialog.name]: Dialog,
        },

        props: {
            visible: {
                type: Boolean,
                default: 0
            },
            poll: {},
        },
        data(){
            return{
                editedPercentageKey: 0,
            }
        },
        mounted(){
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },
            confirmModal() {
                this.$emit('updatePercentage', this.poll);
            },
        }

    }
</script>

<style>

.question-row{
  display:flex;
  align-items: center;
}
.question-row{
  padding: 10px 0px;
  border-bottom: 1px solid;
}

.answers-container{
  display:flex;
}
.answers-container p{
  flex-basis: 30%;
}
.box-counter{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #17a2b8;
  margin-left: 4px;
}
</style>
