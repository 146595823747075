import DashboardLayout from "@/pages/layouts/DashboardLayout";

let tagArRoutes = {
  path: '/tags',
  component: DashboardLayout,
  redirect: '/tags/list',
  children: [
    {
      path: 'list/ar',
      name: 'Arabic Tag List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagList'),
      meta: {
        permissionsCodes: ['ar/tags/index', 'restricted_own/ar/tags/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Arabic Tag',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagForm'),
      meta: {
        permissionsCodes: ['ar/tags/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Arabic Tag',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagForm'),
      meta: {
        permissionsCodes: ['ar/tags/update', 'restricted_own/ar/tags/update'],
      }
    }
  ]
}

export default tagArRoutes;
