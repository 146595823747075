import DashboardLayout from "@/pages/layouts/DashboardLayout";

let liveNewsEsRoutes = {
  path: '/live-news',
  component: DashboardLayout,
  redirect: '/live-news/list',
  children: [
    {
      path: 'list/es',
      name: 'Spanish Live News List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsList'),
      meta: {
        permissionsCodes: ['es/live-news/index', 'restricted_own/es/live-news/index'],
      }
    },
    {
      path: 'create/es',
      name: 'Add Spanish Live News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsForm'),
      meta: {
        permissionsCodes: ['es/live-news/create'],
      }
    },
    {
      path: 'edit/:id/es',
      name: 'Edit Spanish Live News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsForm'),
      meta: {
        permissionsCodes: ['es/live-news/update', 'restricted_own/es/live-news/update'],
      }
    }
  ]
}

export default liveNewsEsRoutes;
