import DashboardLayout from "@/pages/layouts/DashboardLayout";

let casesRoutes = {
  path: '/cases',
  component: DashboardLayout,
  redirect: '/cases/list',
  children: [
    /******************** Arabic routes    **************************************/
    {
      path: 'list/ar',
      name: 'Arabic Cases List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesList'),
      meta: {
        permissionsCodes: ['ar/cases/index', 'restricted_own/ar/cases/index'],
      }
    },
    {
      path: 'subscribers/:id/ar',
      name: 'Arabic Case Subscribers List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesSubscribersList'),
      meta: {
        permissionsCodes: ['ar/cases_subscribers/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Arabic  Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['ar/cases/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Arabic  Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['ar/cases/update', 'restricted_own/ar/cases/update'],
      }
    },

    /******************** English routes    **************************************/
    {
      path: 'list/en',
      name: 'English Cases List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesList'),
      meta: {
        permissionsCodes: ['en/cases/index', 'restricted_own/en/cases/index'],
      }
    },
    {
      path: 'subscribers/:id/en',
      name: 'English Case Subscribers List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesSubscribersList'),
      meta: {
        permissionsCodes: ['en/cases_subscribers/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English  Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['en/cases/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English  Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['en/cases/update', 'restricted_own/en/cases/update'],
      }
    },
    /******************** French routes    **************************************/
    {
      path: 'list/fr',
      name: 'French   Cases List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesList'),
      meta: {
        permissionsCodes: ['fr/cases/index', 'restricted_own/fr/cases/index'],
      }
    },
    {
      path: 'subscribers/:id/fr',
      name: 'French Case Subscribers List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesSubscribersList'),
      meta: {
        permissionsCodes: ['fr/cases_subscribers/index'],
      }
    },
    {
      path: 'create/fr',
      name: 'Add French   Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['fr/cases/create'],
      }
    },
    {
      path: 'edit/:id/fr',
      name: 'Edit French  Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['fr/cases/update', 'restricted_own/fr/cases/update'],
      }
    },
    /******************** Spanish routes    **************************************/
    {
      path: 'list/es',
      name: 'Spanish  Cases List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesList'),
      meta: {
        permissionsCodes: ['es/cases/index', 'restricted_own/es/cases/index'],
      }
    },
    {
      path: 'subscribers/:id/es',
      name: 'Spanish Case Subscribers List',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CasesSubscribersList'),
      meta: {
        permissionsCodes: ['es/cases_subscribers/index'],
      }
    },
    {
      path: 'create/es',
      name: 'Add Spanish Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['es/cases/create'],
      }
    },
    {
      path: 'edit/:id/es',
      name: 'Edit Spanish  Case',
      component: () => import(/* webpackChunkName: "cases" */  '@/pages/cases/CaseForm'),
      meta: {
        permissionsCodes: ['es/cases/update', 'restricted_own/es/cases/update'],
      }
    }
  ]
}

export default casesRoutes;
