<template>
  <div :class="filter.class">
    <div class="form-group has-label select-filter" >
      <label class="my-2">{{filter.label}} </label>
      <el-select :multiple="filter.multiple" class="select-primary"
                 size="large"
                 v-model="filter.value"
                 >
        <el-option v-for="option in options"
                   class="select-primary"
                   :value="option.value"
                   :label="option.label"
                   :key="option.label">
        </el-option>
      </el-select>


    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },

  props: ["filter"],
  data() {
    return {
      options:[],
    }
  },
  created() {
  },
  mounted() {
    this.axios.get(this.filter.url).then((response) => {
      this.options = response.data.items;
    }).catch((error) => {
      console.error(error);
    })
  },
  methods: {
  }
}
</script>

<style>
.select-filter{
  margin: 5px;
}
</style>
