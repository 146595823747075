import DashboardLayout from "@/pages/layouts/DashboardLayout";

let countryRoutes = {
  path: '/countries',
  component: DashboardLayout,
  redirect: '/countries/list',
  children: [
    {
      path: 'list',
      name: 'Countries List',
      component: () => import(/* webpackChunkName: "countries" */  '@/pages/electionResults/countries/CountryList'),
      meta: {
        permissionsCodes: ['countries/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Country Item',
      component: () => import(/* webpackChunkName: "countries" */  '@/pages/electionResults/countries/CountryForm'),
      meta: {
        permissionsCodes: ['countries/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Country Item',
      component: () => import(/* webpackChunkName: "countries" */  '@/pages/electionResults/countries/CountryForm'),
      meta: {
        permissionsCodes: ['countries/update'],
      }
    }
  ]
}

export default countryRoutes;
