import DashboardLayout from "@/pages/layouts/DashboardLayout";

let liveNewsEnRoutes = {
  path: '/live-news',
  component: DashboardLayout,
  redirect: '/live-news/list',
  children: [
    {
      path: 'list/en',
      name: 'English Live News List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsList'),
      meta: {
        permissionsCodes: ['en/live-news/index', 'restricted_own/en/live-news/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English Live News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsForm'),
      meta: {
        permissionsCodes: ['en/live-news/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English Live News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsForm'),
      meta: {
        permissionsCodes: ['en/live-news/update', 'restricted_own/en/live-news/update'],
      }
    }
  ]
}

export default liveNewsEnRoutes;
