import DashboardLayout from "@/pages/layouts/DashboardLayout";

let activityLogsRoutes = {
  path: '/logs',
  component: DashboardLayout,
  redirect: '/logs/list',
  children: [
    {
      path: 'user-actions/:id',
      name: 'User Actions',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/activityLogs/UserActions'),
      meta: {
        // permissionsCodes: 'branches/update',
      }
    },

    {
      path: ':id',
      name: 'Item History',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/activityLogs/ItemHistory'),
      meta: {
        // permissionsCodes: 'branches/update',
      }
    }
  ]
}

export default activityLogsRoutes;
