<template>
    <div :class="filter.class">
        <div class="form-group has-label" >
            <label>{{filter.label}} </label>
            <select class="form-control"  v-if="filter.strategyOptions" v-model="filter.strategy">
                <template v-for="(strategy,strategyIndex) in filter.strategyOptions">
                    <option  :value="strategy.value"> {{strategy.label}}</option>
                </template>
            </select>
            <input :type="filter.type" class="form-control" v-model="filter.value"/>
        </div>
    </div>
</template>

<script>

export default {
    props: ["filter"],
    data() {
        return {}
    },
    created() {

    },
    mounted() {

    },
    methods: {
    }
}
</script>

<style>
.inputs-container {
  display: flex;
  align-items: baseline;
  width: 100%;
}
.inputs-container * {
  margin: 5px;
}
 .custom-input {
  height: calc(1.5em + .75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.main-input {
  flex-grow: 2;
}
</style>
