import DashboardLayout from "@/pages/layouts/DashboardLayout";

let continentRoutes = {
  path: '/continents',
  component: DashboardLayout,
  redirect: '/continents/list',
  children: [
    {
      path: 'list',
      name: 'Continents List',
      component: () => import(/* webpackChunkName: "continents" */  '@/pages/electionResults/continents/ContinentList'),
      meta: {
        permissionsCodes: ['continents/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Continent Item',
      component: () => import(/* webpackChunkName: "continents" */  '@/pages/electionResults/continents/ContinentForm'),
      meta: {
        permissionsCodes: ['continents/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Continent Item',
      component: () => import(/* webpackChunkName: "continents" */  '@/pages/electionResults/continents/ContinentForm'),
      meta: {
        permissionsCodes: ['continents/update'],
      }
    }
  ]
}

export default continentRoutes;
