import DashboardLayout from "@/pages/layouts/DashboardLayout";

let tagFrRoutes = {
  path: '/tags',
  component: DashboardLayout,
  redirect: '/tags/list',
  children: [
    {
      path: 'list/fr',
      name: 'French Tag List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagList'),
      meta: {
        permissionsCodes: ['fr/tags/index', 'restricted_own/fr/tags/index'],
      }
    },
    {
      path: 'create/fr',
      name: 'Add French Tag',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagForm'),
      meta: {
        permissionsCodes: ['fr/tags/create'],
      }
    },
    {
      path: 'edit/:id/fr',
      name: 'Edit French Tag',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagForm'),
      meta: {
        permissionsCodes: ['fr/tags/update', 'restricted_own/fr/tags/update'],
      }
    }
  ]
}

export default tagFrRoutes;
