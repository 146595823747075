import DashboardLayout from "@/pages/layouts/DashboardLayout";

let visualEditorRoutes = {
  path: '/visual-editors',
  component: DashboardLayout,
  redirect: '/visual-editors',
  children: [

    //********** menus
    {
      path: 'menus/ar',
      name: 'Arabic menus',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuList'),
      meta: {
        permissionsCodes: ['ar/menus/index'],
      }
    },
    {
      path: 'menus/en',
      name: 'English menus',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuList'),
      meta: {
        permissionsCodes: ['en/menus/index'],
      }
    },
    {
      path: 'menus/fr',
      name: 'French menus',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuList'),
      meta: {
        permissionsCodes: ['fr/menus/index'],
      }
    },
    {
      path: 'menus/es',
      name: 'Spanish menus',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuList'),
      meta: {
        permissionsCodes: ['es/menus/index'],
      }
    },


    //********* menus
    {
      path: 'menus/edit/:id/ar',
      name: 'Edit Arabic menu',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuForm'),
      meta: {
        permissionsCodes: ['ar/menus/update'],

      }
    },
    {
      path: 'menus/edit/:id/en',
      name: 'Edit English menu',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuForm'),
      meta: {
        permissionsCodes: ['en/menus/update'],
      }
    },
    {
      path: 'menus/edit/:id/fr',
      name: 'Edit French menu',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuForm'),
      meta: {
        permissionsCodes: ['fr/menus/update'],
      }
    },
    {
      path: 'menus/edit/:id/es',
      name: 'Edit Spanish menu',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/menus/MenuForm'),
      meta: {
        permissionsCodes: ['es/menus/update'],
      }
    },
    /************ Visual Editor *********************/
    {
      path: 'build/ar',
      name: 'Arabic Home Visual Editor',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/homePage/HomePageRegularModeVisualEditor'),
      meta: {
        permissionsCodes: ['ar/visual-editor/save-content'],
      }
    },
    {
      path: 'build/en',
      name: 'English Home Visual Editor',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/homePage/HomePageRegularModeVisualEditor'),
      meta: {
        permissionsCodes: ['en/visual-editor/save-content'],
      }
    },
    {
      path: 'build/fr',
      name: 'French Home Visual Editor',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/homePage/HomePageRegularModeVisualEditor'),
      meta: {
        permissionsCodes: ['fr/visual-editor/save-content'],
      }
    },
    {
      path: 'build/es',
      name: 'Spanish Home Visual Editor',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/homePage/HomePageRegularModeVisualEditor'),
      meta: {
        permissionsCodes: ['es/visual-editor/save-content'],
      }
    },
    /************ Campaign Mode *********************/
    {
      path: 'campaign/ar',
      name: 'Arabic Campaign',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/Campaign'),
      meta: {
        permissionsCodes: ['ar/campaign/save-content'],
      }
    },
    {
      path: 'campaign/en',
      name: 'English Campaign',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/Campaign'),
      meta: {
        permissionsCodes: ['en/campaign/save-content'],
      }
    },
    {
      path: 'campaign/fr',
      name: 'French Campaign',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/Campaign'),
      meta: {
        permissionsCodes: ['fr/campaign/save-content'],
      }
    },
    {
      path: 'campaign/es',
      name: 'Spanish Campaign',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/Campaign'),
      meta: {
        permissionsCodes: ['es/campaign/save-content'],
      }
    },
    /************ Live Coverage *********************/
    {
      path: 'live-coverage/ar',
      name: 'Arabic Live Coverage',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/LiveCoverage'),
      meta: {
        permissionsCodes: ['ar/live-coverage/save-content'],
      }
    },
    {
      path: 'live-coverage/en',
      name: 'English Live Coverage',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/LiveCoverage'),
      meta: {
        permissionsCodes: ['en/live-coverage/save-content'],
      }
    },
    {
      path: 'live-coverage/fr',
      name: 'French Live Coverage',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/LiveCoverage'),
      meta: {
        permissionsCodes: ['fr/live-coverage/save-content'],
      }
    },
    {
      path: 'live-coverage/es',
      name: 'Spanish Live Coverage',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/LiveCoverage'),
      meta: {
        permissionsCodes: ['es/live-coverage/save-content'],
      }
    },
  ]
}

export default visualEditorRoutes;
