<template>
    <div :class="filter.class">
      <div class="switch-filter-container">
        <div class="form-group switch-filter" >
          <label>{{filter.label}} </label>
          <l-switch v-model="filter.value" class="mx-3">
            <i class="fa fa-check" slot="on"></i>
            <i class="fa fa-times" slot="off"></i>
          </l-switch>

        </div>
      </div>

    </div>
</template>

<script>
import { Switch as LSwitch} from "@/components";

export default {
    components: {
        LSwitch,
    },

    props: ["filter"],
    data() {
        return {}
    },
    created() {

    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style>
.switch-filter{
    margin: 5px;
}
.switch-filter-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
}
</style>
