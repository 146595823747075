const sharedMethods = {
  getEditorConfig: function () {
    return {
      height: 400,
      paste_data_images: true,
      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
        "insertdatetime media nonbreaking save table contextmenu directionality",
        "emoticons template paste textcolor colorpicker textpattern"
      ],
      toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | customHighlight1Button | customHighlight2Button",
      toolbar2: "print preview media | forecolor backcolor emoticons",
      image_advtab: true,
      branding: false,
      content_style: ".highlight_type_1{background-color: #246034;color:#ffffffff} .highlight_type_2{background-color: #efefef;}",
      setup: function (editor) {
        editor.ui.registry.addButton("customHighlight1Button", {
          text: "Highlight 1",
          onAction: function (_) {
            editor.dom.toggleClass(tinyMCE.activeEditor.selection.getNode(),'highlight_type_1');
          }
        });
        editor.ui.registry.addButton("customHighlight2Button", {
          text: "Highlight 2",
          onAction: function (_) {
            editor.dom.toggleClass(tinyMCE.activeEditor.selection.getNode(),'highlight_type_2');
          }
        });
      }
    }
  },
  getImagesExtensions() {
    return ['png', 'jpeg', 'gif', 'jpg', 'svg'];
  },
  getVideoExtensions() {
    return ['webm', 'webm', 'mkv', 'flv', 'gif', 'mov', 'wmv', 'mp4', '3gp', 'vob', 'ogg', 'gifv']
  },
};

export default sharedMethods;

