import DashboardLayout from "@/pages/layouts/DashboardLayout";

let classificationFrRoutes = {
  path: '/classifications',
  component: DashboardLayout,
  redirect: '/classifications/list',
  children: [
    {
      path: 'list/fr',
      name: 'French Classification List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationList'),
      meta: {
        permissionsCodes: ['fr/classifications/index', 'restricted_own/fr/classifications/index'],
      }
    },
    {
      path: 'create/fr',
      name: 'Add French Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['fr/classifications/create'],
      }
    },
    {
      path: 'edit/:id/fr',
      name: 'Edit French Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['fr/classifications/update', 'restricted_own/fr/classifications/update'],
      }
    }
  ]
}

export default classificationFrRoutes;
