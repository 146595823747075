import DashboardLayout from "@/pages/layouts/DashboardLayout";

let kadaaRoutes = {
  path: '/kadaa',
  component: DashboardLayout,
  redirect: '/kadaa/list',
  children: [
    {
      path: 'list',
      name: 'الدائرة الصغرى List',
      component: () => import(/* webpackChunkName: "kadaa" */  '@/pages/lebanonElectionResults/kadaa/KadaaList'),
      meta: {
        permissionsCodes: ['electoral-districts/index'],
      }
    },
    {
      path: 'create',
      name: 'Add الدائرة الصغرى',
      component: () => import(/* webpackChunkName: "kadaa" */  '@/pages/lebanonElectionResults/kadaa/KadaaForm'),
      meta: {
        permissionsCodes: ['electoral-districts/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit الدائرة الصغرى',
      component: () => import(/* webpackChunkName: "kadaa" */  '@/pages/lebanonElectionResults/kadaa/KadaaForm'),
      meta: {
        permissionsCodes: ['electoral-districts/update'],
      }
    }
  ]
}

export default kadaaRoutes;
