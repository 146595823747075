import DashboardLayout from "@/pages/layouts/DashboardLayout";

let newsEnRoutes = {
  path: '/news-en',
  component: DashboardLayout,
  redirect: '/news-en/list',
  children: [
    {
      path: 'list/en',
      name: 'English News List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/news/NewsList'),
      meta: {
        permissionsCodes: ['en/news/index', 'restricted_own/en/news/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/news/NewsForm'),
      meta: {
        permissionsCodes: ['en/news/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/news/NewsForm'),
      meta: {
        permissionsCodes: ['en/news/update', 'restricted_own/en/news/update'],
      }
    }
  ]
}

export default newsEnRoutes;
