import DashboardLayout from "@/pages/layouts/DashboardLayout";

let tagEnRoutes = {
  path: '/tags',
  component: DashboardLayout,
  redirect: '/tags/list',
  children: [
    {
      path: 'list/en',
      name: 'Tag English List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagList'),
      meta: {
        permissionsCodes: ['en/tags/index', 'restricted_own/en/tags/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English Tag',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagForm'),
      meta: {
        permissionsCodes: ['en/tags/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English Tag',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/tags/TagForm'),
      meta: {
        permissionsCodes: ['en/tags/update', 'restricted_own/en/tags/update'],
      }
    }
  ]
}

export default tagEnRoutes;
