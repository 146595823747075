import DashboardLayout from '@/pages/layouts/DashboardLayout.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import Overview from '@/pages/Overview.vue'
import Login from '@/pages/authPages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import branchesRoutes from "@/routes/pages/branchesRoutes";
import pagesRoutes from "@/routes/pages/pagesRoutes";
import redirectionRoutes from "@/routes/pages/redirectionPagesRoutes";
import contentRoutes from "@/routes/pages/contentPagesRoutes";
import panelGroupsRoutes from "@/routes/panels/panelGroupsRoutes";
import panelRoutes from "@/routes/panels/panelRoutes";
import settingsRoutes from "@/routes/general/settingsRoutes";
import UpdatePassword from "@/pages/profile/UpdatePassword";
import UpdateProfile from "@/pages/profile/UpdateProfile";
import ForgetPassword from "@/pages/authPages/ForgetPassword";
import ResetPassword from "@/pages/authPages/ResetPassword";
import newsletterRoutes from "@/routes/general/newsletterRoutes";
import mediaInfoRoutes from "@/routes/general/mediaInfoRoutes";
import activityLogsRoutes from "@/routes/activityLogs/activityLogsRoutes";
import notificationsRoutes from "@/routes/notifications/notificationsRoutes";
import visualEditorRoutes from "@/routes/visualEditors/visualEditorRoutes";
import VerifyGoogle2fa from "@/pages/authPages/VerifyGoogle2fa";
import pollRoutes from "./polls/pollRoutes";
import authorArRoutes from "./news/ar/authorArRoutes";
import categoryArRoutes from "./news/ar/categoryArRoutes";
import categoryEnRoutes from "./news/en/categoryEnRoutes";
import sourceArRoutes from "./news/ar/sourceArRoutes";
import tagArRoutes from "./news/ar/tagArRoutes";
import newsArRoutes from "./news/ar/newsArRoutes";
import liveNewsArRoutes from "./news/ar/liveNewsArRoutes";
import categoryFrRoutes from "./news/fr/categoryFrRoutes";
import categoryEsRoutes from "./news/es/categoryEsRoutes";
import sourceEnRoutes from "./news/en/sourceEnRoutes";
import sourceFrRoutes from "./news/fr/sourceFrRoutes";
import sourceEsRoutes from "./news/es/sourceEsRoutes";
import authorEnRoutes from "./news/en/authorEnRoutes";
import authorFrRoutes from "./news/fr/authorFrRoutes";
import authorEsRoutes from "./news/es/authorEsRoutes";
import tagEnRoutes from "./news/en/tagEnRoutes";
import tagFrRoutes from "./news/fr/tagFrRoutes";
import tagEsRoutes from "./news/es/tagEsRoutes";
import newsEnRoutes from "@/routes/news/en/newsEnRoutes";
import newsEsRoutes from "@/routes/news/es/newsEsRoutes";
import liveNewsFrRoutes from "@/routes/news/fr/liveNewsFrRoutes";
import newsFrRoutes from "@/routes/news/fr/newsFrRoutes";
import liveNewsEsRoutes from "@/routes/news/es/liveNewsEsRoutes";
import liveNewsEnRoutes from "@/routes/news/en/liveNewsEnRoutes";
import casesRoutes from "@/routes/cases/casesRoutes";
import classificationArRoutes from "./news/ar/classificationArRoutes";
import classificationEnRoutes from "./news/en/classificationEnRoutes";
import classificationEsRoutes from "./news/es/classificationEsRoutes";
import classificationFrRoutes from "./news/fr/classificationFrRoutes";
import jobRequestsRoutes from "@/routes/general/JobRequestsRoutes";
import contactusRoutes from "./general/contactusRoutes";
import mobileSettingsRoutes from "./general/mobileSettingsRoutes";
import countryRoutes from "./electionResults/countriesRoutes";
import electoralDistrictRoutes from "./lebanonElectionResults/electoralDistrictRoutes";
import governorateRoutes from "./lebanonElectionResults/governorateRoutes";
import continentRoutes from "./electionResults/continentRoutes";
import kadaaRoutes from "./lebanonElectionResults/kadaaRoutes";
import winnerRoutes from "./lebanonElectionResults/winnerRoutes";



let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },

  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};
let verifyGoogle2faPage = {
  path: '/verify-google2fa',
  name: 'verify google2fa',
  component: VerifyGoogle2fa
};
let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  verifyGoogle2faPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  redirectionRoutes,
  usersRoutes,
  branchesRoutes,
  contentRoutes,
  pagesRoutes,
  panelGroupsRoutes,
  panelRoutes,
  settingsRoutes,
  newsletterRoutes,
  jobRequestsRoutes,
  dashboardRoutes,
  mediaInfoRoutes,
  activityLogsRoutes,
  notificationsRoutes,
  casesRoutes,
  visualEditorRoutes,
  pollRoutes,
  authorArRoutes,
  categoryArRoutes,
  categoryEnRoutes,
  categoryFrRoutes,
  categoryEsRoutes,
  sourceArRoutes,
  tagArRoutes,
  sourceEnRoutes,
  sourceFrRoutes,
  sourceEsRoutes,
  authorEnRoutes,
  authorFrRoutes,
  authorEsRoutes,
  tagEnRoutes,
  tagFrRoutes,
  tagEsRoutes,
  liveNewsArRoutes,
  newsArRoutes,
  newsEnRoutes,
  newsEsRoutes,
  newsFrRoutes,
  liveNewsEnRoutes,
  liveNewsEsRoutes,
  liveNewsFrRoutes,
  classificationArRoutes,
  classificationEnRoutes,
  classificationEsRoutes,
  classificationFrRoutes,
  contactusRoutes,
  mobileSettingsRoutes,
  continentRoutes,
  countryRoutes,
  electoralDistrictRoutes,
  governorateRoutes,
  kadaaRoutes,
  winnerRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
