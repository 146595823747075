<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters['auth/haveOneOfPermissions'](['notifications/index'])"
          :link="{name: 'Notifications', path: '/notifications/list', icon: 'nc-icon nc-notification-70'}"></sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                  (['ar/cases/index','restricted_own/ar/cases/index',
                                  'en/cases/index','restricted_own/en/cases/index',
                                  'fr/cases/index','restricted_own/fr/cases/index',
                                  'es/cases/index','restricted_own/es/cases/index'])"
                      :link="{name: 'Cases', icon: 'fa fa-folder'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/cases/index','restricted_own/ar/cases/index'])"
            :link="{name: 'Arabic', path: '/cases/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/cases/index','restricted_own/en/cases/index'])"
            :link="{name: 'English', path: '/cases/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/cases/index','restricted_own/fr/cases/index'])"
            :link="{name: 'French', path: '/cases/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/cases/index','restricted_own/es/cases/index'])"
            :link="{name: 'Spanish', path: '/cases/list/es'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                  (['ar/live-news/index','restricted_own/ar/live-news/index',
                                  'en/live-news/index','restricted_own/en/live-news/index',
                                  'fr/live-news/index','restricted_own/fr/live-news/index',
                                  'es/live-news/index','restricted_own/es/live-news/index'])"
                      :link="{name: 'Live News', icon: 'nc-icon nc-button-play'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/live-news/index','restricted_own/ar/live-news/index'])"
            :link="{name: 'Arabic', path: '/live-news/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/live-news/index','restricted_own/en/live-news/index'])"
            :link="{name: 'English', path: '/live-news/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/live-news/index','restricted_own/fr/live-news/index'])"
            :link="{name: 'French', path: '/live-news/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/live-news/index','restricted_own/es/live-news/index'])"
            :link="{name: 'Spanish', path: '/live-news/list/es'}"></sidebar-item>
        </sidebar-item>



        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/categories/index', 'restricted_own/ar/categories/index',
                                            'ar/tags/index', 'restricted_own/ar/tags/index',
                                            'ar/classifications/index', 'restricted_own/ar/classifications/index',
                                            'ar/authors/index', 'restricted_own/ar/authors/index',
                                            'ar/sources/index', 'restricted_own/ar/sources/index',
                                            'ar/news/index', 'restricted_own/ar/news/index',])"
                      :link="{name: 'Arabic News', icon: 'nc-icon nc-paper-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/news/index','restricted_own/ar/news/index'])"
            :link="{name: 'News', path: '/news-ar/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/categories/index','restricted_own/ar/categories/index'])"
            :link="{name: 'Categories', path: '/categories/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/classifications/index','restricted_own/ar/classifications/index'])"
            :link="{name: 'Classifications', path: '/classifications/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/tags/index','restricted_own/ar/tags/index'])"
            :link="{name: 'Tags', path: '/tags/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/authors/index','restricted_own/ar/authors/index'])"
            :link="{name: 'Authors', path: '/authors/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/sources/index','restricted_own/ar/sources/index'])"
            :link="{name: 'Sources', path: '/sources/list/ar'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['en/categories/index', 'restricted_own/en/categories/index',
                                            'en/tags/index', 'restricted_own/en/tags/index',
                                            'en/classifications/index', 'restricted_own/en/classifications/index',
                                            'en/authors/index', 'restricted_own/en/authors/index',
                                            'en/sources/index', 'restricted_own/en/sources/index',
                                            'en/news/index', 'restricted_own/en/news/index',
                                            ])"
                      :link="{name: 'English News', icon: 'nc-icon nc-paper-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/news/index','restricted_own/en/news/index'])"
            :link="{name: 'News', path: '/news-en/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/categories/index','restricted_own/en/categories/index'])"
            :link="{name: 'Categories', path: '/categories/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/classifications/index','restricted_own/en/classifications/index'])"
            :link="{name: 'Classifications', path: '/classifications/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/tags/index','restricted_own/en/tags/index'])"
            :link="{name: 'Tags', path: '/tags/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/authors/index','restricted_own/en/authors/index'])"
            :link="{name: 'Authors', path: '/authors/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/sources/index','restricted_own/en/sources/index'])"
            :link="{name: 'Sources', path: '/sources/list/en'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['fr/categories/index', 'restricted_own/fr/categories/index',
                                            'fr/tags/index', 'restricted_own/fr/tags/index',
                                            'fr/classifications/index', 'restricted_own/fr/classifications/index',
                                            'fr/authors/index', 'restricted_own/fr/authors/index',
                                            'fr/sources/index', 'restricted_own/fr/sources/index',
                                            'fr/news/index', 'restricted_own/fr/news/index',
                                            ])"
                      :link="{name: 'French News', icon: 'nc-icon nc-paper-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/news/index','restricted_own/fr/news/index'])"
            :link="{name: 'News', path: '/news-fr/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/categories/index','restricted_own/fr/categories/index'])"
            :link="{name: 'Categories', path: '/categories/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/classifications/index','restricted_own/fr/classifications/index'])"
            :link="{name: 'Classifications', path: '/classifications/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/tags/index','restricted_own/fr/tags/index'])"
            :link="{name: 'Tags', path: '/tags/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/authors/index','restricted_own/fr/authors/index'])"
            :link="{name: 'Authors', path: '/authors/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/sources/index','restricted_own/fr/sources/index'])"
            :link="{name: 'Sources', path: '/sources/list/fr'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['es/categories/index', 'restricted_own/es/categories/index',
                                            'es/tags/index', 'restricted_own/es/tags/index',
                                            'es/classifications/index', 'restricted_own/es/classifications/index',
                                            'es/authors/index', 'restricted_own/es/authors/index',
                                            'es/sources/index', 'restricted_own/es/sources/index',
                                            'es/news/index', 'restricted_own/es/news/index',
                                            ])"
                      :link="{name: 'Spanish News', icon: 'nc-icon nc-paper-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/news/index','restricted_own/es/news/index'])"
            :link="{name: 'News', path: '/news-es/list/es'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/categories/index','restricted_own/es/categories/index'])"
            :link="{name: 'Categories', path: '/categories/list/es'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/classifications/index','restricted_own/es/classifications/index'])"
            :link="{name: 'Classifications', path: '/classifications/list/es'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/tags/index','restricted_own/es/tags/index'])"
            :link="{name: 'Tags', path: '/tags/list/es'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/authors/index','restricted_own/es/authors/index'])"
            :link="{name: 'Authors', path: '/authors/list/es'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/sources/index','restricted_own/es/sources/index'])"
            :link="{name: 'Sources', path: '/sources/list/es'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/polls/index','restricted_own/ar/polls/index',
                                  'en/polls/index','restricted_own/en/polls/index',
                                  'fr/polls/index','restricted_own/fr/polls/index',
                                  'es/polls/index','restricted_own/es/polls/index'])"
                      :link="{name: 'Polls', icon: 'nc-icon nc-notes'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['ar/polls/index','restricted_own/ar/polls/index'])"
            :link="{name: 'Arabic', path: '/polls/list/ar'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['en/polls/index','restricted_own/en/polls/index'])"
            :link="{name: 'English', path: '/polls/list/en'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['fr/polls/index','restricted_own/fr/polls/index'])"
            :link="{name: 'French', path: '/polls/list/fr'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['es/polls/index','restricted_own/es/polls/index'])"
            :link="{name: 'Spanish', path: '/polls/list/es'}"></sidebar-item>
        </sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/visual-editor/save-content','en/visual-editor/save-content','fr/visual-editor/save-content','es/visual-editor/save-content'])"
                        :link="{name: 'Visual Editors', icon: 'nc-icon nc-settings-90'}">
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/visual-editor/save-content'])"
                          :link="{name: 'Arabic Home', path: '/visual-editors/build/ar'}"></sidebar-item>
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/visual-editor/save-content'])"
                          :link="{name: 'English Home', path: '/visual-editors/build/en'}"></sidebar-item>
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['fr/visual-editor/save-content'])"
                          :link="{name: 'French Home', path: '/visual-editors/build/fr'}"></sidebar-item>
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['es/visual-editor/save-content'])"
                          :link="{name: 'Spanish Home', path: '/visual-editors/build/es'}"></sidebar-item>
          </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/live-coverage/save-content','en/live-coverage/save-content','fr/live-coverage/save-content','es/live-coverage/save-content'])"
                      :link="{name: 'Live Coverage', icon: 'nc-icon nc-button-play'}">
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/live-coverage/save-content'])"
                        :link="{name: 'Arabic Home', path: '/visual-editors/live-coverage/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/live-coverage/save-content'])"
                        :link="{name: 'English Home', path: '/visual-editors/live-coverage/en'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['fr/live-coverage/save-content'])"
                        :link="{name: 'French Home', path: '/visual-editors/live-coverage/fr'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['es/live-coverage/save-content'])"
                        :link="{name: 'Spanish Home', path: '/visual-editors/live-coverage/es'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/campaign/save-content','en/campaign/save-content','fr/campaign/save-content','es/campaign/save-content'])"
                      :link="{name: 'campaign', icon: 'nc-icon nc-notification-70'}">
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/campaign/save-content'])"
                        :link="{name: 'Arabic Home', path: '/visual-editors/campaign/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/campaign/save-content'])"
                        :link="{name: 'English Home', path: '/visual-editors/campaign/en'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['fr/campaign/save-content'])"
                        :link="{name: 'French Home', path: '/visual-editors/campaign/fr'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['es/campaign/save-content'])"
                        :link="{name: 'Spanish Home', path: '/visual-editors/campaign/es'}"></sidebar-item>
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['/visual-editors/menus/es','fr/menus/index','es/menus/index','en/menus/index','ar/menus/index'])"
                      :link="{name: 'Menus ', icon: 'nc-icon nc-grid-45'}">
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/menus/index'])"
                        :link="{name: 'Arabic Menus', path: '/visual-editors/menus/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/menus/index'])"
                        :link="{name: 'English Menus', path: '/visual-editors/menus/en'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['fr/menus/index'])"
                        :link="{name: 'French Menus', path: '/visual-editors/menus/fr'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['es/menus/index'])"
                        :link="{name: 'Spanish Menus', path: '/visual-editors/menus/es'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['electoral-districts/index','governorates/index',
                       'departments/index'])"
                      :link="{name: 'Lebanon Results', icon: 'nc-icon nc-chart-bar-32'}">


          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['governorates/index'])"
            :link="{name: 'Governorates', path: '/governorates/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['departments/index'])"
            :link="{name: 'Electoral Districts', path: '/electoral-districts/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['electoral-districts/index'])"
            :link="{name: 'الدائرة الصغرى', path: '/kadaa/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['continents/index','countries/index'])"
                      :link="{name: 'Election Results', icon: 'nc-icon nc-chart-bar-32'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['continents/index'])"
            :link="{name: 'Continents', path: '/continents/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['countries/index'])"
            :link="{name: 'countries', path: '/countries/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])"
                      :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])"
                        :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['job-requests/index'])"
                        :link="{name: 'Job Requests ', icon: 'nc-icon  nc-badge', path: '/job-requests'}">
          </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index'])"
            :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index'])"
            :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index'])"
            :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index'])"
            :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['mobile-settings/get','settings/index'])"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index'])"
                        :link="{name: 'Website Settings', path: '/settings/list'}">
          </sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['mobile-settings/get'])"
                        :link="{name: 'Mobile Settings', path: '/mobile-settings/get'}">
          </sidebar-item>

        </sidebar-item>



        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/deleted-users')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/deleted-users')"
                        :link="{name: 'Deleted Users', path: '/users/DeletedUsersList'}"></sidebar-item>

        </sidebar-item>


      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
