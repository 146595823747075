<template>
    <div>
    <template  v-for="(filter, filterIndex) in filters">

            <simple-filter  v-if="filter.frontType === 'simple'"
            class=" mt-4" id="simple-filter"
            :filter="filter"></simple-filter>

            <switch-filter v-if="filter.frontType === 'switch'"
             class=" mt-4" id="radio-filter"
            :filter="filter"></switch-filter>

            <select-filter v-if="filter.frontType === 'select'"
                     class=" mt-4" id="select-filter"
                     :filter="filter"></select-filter>
      <paginated-select-filter v-if="filter.frontType === 'paginated-select'"
                     class=" mt-4" id="paginated-select-filter"
                     :filter="filter"></paginated-select-filter>

            <select-with-fixed-lists-filter v-if="filter.frontType === 'select-with-fixed-list'"
                     class=" mt-4" id="select-with-fixed-list"
                     :filter="filter"></select-with-fixed-lists-filter>

    </template>
    </div>
</template>


<script>
import SimpleFilter from './SimpleFilter.vue';
import SwitchFilter from './SwitchFilter.vue';
import SelectFilter from './SelectFilter';
import SelectWithFixedListsFilter from './SelectWithFixedListsFilter';
import PaginatedSelectFilter from "./PaginatedSelectFilter";

export default {
    components: {PaginatedSelectFilter, SimpleFilter, SwitchFilter,SelectFilter,SelectWithFixedListsFilter },
    props: ["filters"],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
