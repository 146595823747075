import DashboardLayout from "@/pages/layouts/DashboardLayout";

let jobRequestsRoutes = {
  path: '/job-requests',
  component: DashboardLayout,
  redirect: '/job-requests/list',
  children: [
    {
      path: 'list',
      name: 'Job Requests List',
      component:()=>import(/* webpackChunkName: "newsletter" */ '@/pages/general/job-requests/jobRequests'),
      meta: {
        permissionsCodes: ['job-requests/index'],
      }
    },
  ]
}

export default jobRequestsRoutes;
