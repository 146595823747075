import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sourceArRoutes = {
  path: '/sources',
  component: DashboardLayout,
  redirect: '/sources/list',
  children: [
    {
      path: 'list/ar',
      name: 'Arabic Source List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceList'),
      meta: {
        permissionsCodes: ['ar/sources/index', 'restricted_own/ar/sources/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Arabic Source',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceForm'),
      meta: {
        permissionsCodes: ['ar/sources/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Arabic Source',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceForm'),
      meta: {
        permissionsCodes: ['ar/sources/update', 'restricted_own/ar/sources/update'],
      }
    }
  ]
}

export default sourceArRoutes;
