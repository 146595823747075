import DashboardLayout from "@/pages/layouts/DashboardLayout";

let mobileSettingsRoutes = {
  path: '/mobile-settings',
  component: DashboardLayout,
  redirect: '/mobile-settings/get',
  children: [
    {
      path: 'Get',
      name: 'Mobile Settings List',
      component: () => import(/* webpackChunkName: "settings" */  '@/pages/general/settings/MobileSettingForm'),
      meta: {
        permissionsCodes: ['mobile-settings/get'],
      }
    },
  ]
}

export default mobileSettingsRoutes;
