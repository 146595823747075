import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sourceEnRoutes = {
  path: '/sources',
  component: DashboardLayout,
  redirect: '/sources/list',
  children: [
    {
      path: 'list/en',
      name: 'English Source List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceList'),
      meta: {
        permissionsCodes: ['en/sources/index', 'restricted_own/en/sources/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English Source',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceForm'),
      meta: {
        permissionsCodes: ['en/sources/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English Source',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceForm'),
      meta: {
        permissionsCodes: ['en/sources/update', 'restricted_own/en/sources/update'],
      }
    }
  ]
}

export default sourceEnRoutes;
