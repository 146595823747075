import Vue from 'vue'
import VueRouter from "vue-router";
import routes from './routes';
import store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history'
});

const unauthenticatedNames = ['Login','Forget Password','Reset Password','verify google2fa','Not Found'];

router.beforeEach((to, from, next) => {

  if (unauthenticatedNames.includes(to.name) && store.getters['auth/authenticated']) { // trying access login when user has token
    return next({
      name: 'Overview'
    });
  } else if (!unauthenticatedNames.includes(to.name) && !store.getters['auth/authenticated']) { // trying access pages without authentication
    return next({
      name: 'Login'
    });
  } else if (!unauthenticatedNames.includes(to.name) && store.getters['auth/authenticated']) { // authorization
    if (to.meta.permissionsCodes) {
      if (!store.getters['auth/haveOneOfPermissions'](to.meta.permissionsCodes))
        return next({
          name: 'Overview'
        });
    }
  }
  return next();
});

export default router;
