import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sourceFrRoutes = {
  path: '/sources',
  component: DashboardLayout,
  redirect: '/sources/list',
  children: [
    {
      path: 'list/fr',
      name: 'French Source List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceList'),
      meta: {
        permissionsCodes: ['fr/sources/index', 'restricted_own/fr/sources/index'],
      }
    },
    {
      path: 'create/fr',
      name: 'Add French Source',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceForm'),
      meta: {
        permissionsCodes: ['fr/sources/create'],
      }
    },
    {
      path: 'edit/:id/fr',
      name: 'Edit French Source',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/sources/SourceForm'),
      meta: {
        permissionsCodes: ['fr/sources/update', 'restricted_own/fr/sources/update'],
      }
    }
  ]
}

export default sourceFrRoutes;
