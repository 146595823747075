import DashboardLayout from "@/pages/layouts/DashboardLayout";

let classificationEnRoutes = {
  path: '/classifications',
  component: DashboardLayout,
  redirect: '/classifications/list',
  children: [
    {
      path: 'list/en',
      name: 'English Classification List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationList'),
      meta: {
        permissionsCodes: ['en/classifications/index', 'restricted_own/en/classifications/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['en/classifications/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['en/classifications/update', 'restricted_own/en/classifications/update'],
      }
    }
  ]
}

export default classificationEnRoutes;
