import DashboardLayout from "@/pages/layouts/DashboardLayout";

let winnerRoutes = {
  path: '/election-winners',
  component: DashboardLayout,
  redirect: '/election-winners/list',
  children: [
    {
      path: 'list/:kadaa_id',
      name: 'Winner List',
      component: () => import(/* webpackChunkName: "election-winners" */  '@/pages/lebanonElectionResults/winners/ElectionWinnerList'),
      meta: {
        permissionsCodes: ['election-winners/index'],
      }
    },
    {
      path: 'create/:kadaa_id',
      name: 'Add Winner',
      component: () => import(/* webpackChunkName: "election-winners" */  '@/pages/lebanonElectionResults/winners/ElectionWinnerForm'),
      meta: {
        permissionsCodes: ['election-winners/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Winner',
      component: () => import(/* webpackChunkName: "election-winners" */  '@/pages/lebanonElectionResults/winners/ElectionWinnerForm'),
      meta: {
        permissionsCodes: ['election-winners/update'],
      }
    }
  ]
}

export default winnerRoutes;
