import DashboardLayout from "@/pages/layouts/DashboardLayout";

let pollRoutes = {
  path: '/polls',
  component: DashboardLayout,
  redirect: '/polls/list',
  children: [
    /********************   Arabic routes    **************************************/
    {
      path: 'list/ar',
      name: 'Arabic Poll List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollList'),
      meta: {
        permissionsCodes: ['ar/polls/index', 'restricted_own/ar/polls/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Arabic Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['ar/polls/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Arabic Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['ar/polls/update', 'restricted_own/ar/polls/update'],
      }
    },
    /********************   English routes    **************************************/
    {
      path: 'list/en',
      name: 'English Poll List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollList'),
      meta: {
        permissionsCodes: ['en/polls/index', 'restricted_own/en/polls/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add English Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['en/polls/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit English Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['en/polls/update', 'restricted_own/en/polls/update'],
      }
    },

    /********************   French routes    ***************************************/
    {
      path: 'list/fr',
      name: 'French Poll List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollList'),
      meta: {
        permissionsCodes: ['fr/polls/index', 'restricted_own/fr/polls/index'],
      }
    },
    {
      path: 'create/fr',
      name: 'Add French Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['fr/polls/create'],
      }
    },
    {
      path: 'edit/:id/fr',
      name: 'Edit French Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['fr/polls/update', 'restricted_own/fr/polls/update'],
      }
    },
    /********************   Spanish routes    **************************************/
    {
      path: 'list/es',
      name: 'Spanish Poll List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollList'),
      meta: {
        permissionsCodes: ['es/polls/index', 'restricted_own/es/polls/index'],
      }
    },
    {
      path: 'create/es',
      name: 'Add Spanish Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['es/polls/create'],
      }
    },
    {
      path: 'edit/:id/es',
      name: 'Edit Spanish Poll',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/polls/PollForm'),
      meta: {
        permissionsCodes: ['es/polls/update', 'restricted_own/es/polls/update'],
      }
    },
  ]
}

export default pollRoutes;
