import DashboardLayout from "@/pages/layouts/DashboardLayout";

let classificationArRoutes = {
  path: '/classifications',
  component: DashboardLayout,
  redirect: '/classifications/list',
  children: [
    {
      path: 'list/ar',
      name: 'Arabic Classification List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationList'),
      meta: {
        permissionsCodes: ['ar/classifications/index', 'restricted_own/ar/classifications/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Arabic Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['ar/classifications/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Arabic Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['ar/classifications/update', 'restricted_own/ar/classifications/update'],
      }
    }
  ]
}

export default classificationArRoutes;
