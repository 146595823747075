import DashboardLayout from "@/pages/layouts/DashboardLayout";

let electoralDistrictRoutes = {
  path: '/electoral-districts',
  component: DashboardLayout,
  redirect: '/electoral-districts/list',
  children: [
    {
      path: 'list',
      name: 'Electoral Districts List',
      component: () => import(/* webpackChunkName: "electoral-districts" */  '@/pages/lebanonElectionResults/electoralDistrict/ElectoralDistrictList'),
      meta: {
        permissionsCodes: ['departments/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Electoral District Item',
      component: () => import(/* webpackChunkName: "electoral-districts" */  '@/pages/lebanonElectionResults/electoralDistrict/ElectoralDistrictForm'),
      meta: {
        permissionsCodes: ['departments/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit electoral District Item',
      component: () => import(/* webpackChunkName: "electoral-districts" */  '@/pages/lebanonElectionResults/electoralDistrict/ElectoralDistrictForm'),
      meta: {
        permissionsCodes: ['departments/update'],
      }
    }
  ]
}

export default electoralDistrictRoutes;
