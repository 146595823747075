import Vue from 'vue'
import LightBootstrap from './light-bootstrap-main'

import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index';
import router from './routes/router'
import PrimeVue from 'primevue/config';
import'primevue/resources/themes/saga-blue/theme.css';
import'primevue/resources/primevue.min.css';
import'primeicons/primeicons.css';
import sharedMethods from './sharedMethods';
import vSelectPage from 'v-selectpage';
import FileManager from 'laravel-file-manager';
import * as Sentry from "@sentry/vue";
require('@/store/subscriber');
Vue.use(FileManager, {store});
Vue.use(LightBootstrap);
Vue.use(VueAxios, axios);
Vue.use(PrimeVue);
Vue.use(vSelectPage, {
    // server side data loader
    dataLoad: function(vue, data, params){
        return new Promise((resolve, reject)=>{
            axios.post(url, params).then(resp => resolve(resp), resp => reject(resp))
        })
    }
});

Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401 && store.getters['auth/authenticated']) {
    store.dispatch("auth/handleUnAuthenticatedResponse").then(() => {
      router.push('/login');
    });
  }
  return Promise.reject(error);
});


Vue.mixin({
  methods: sharedMethods
})


Sentry.init({
  Vue,
  dsn: "https://a9ea2997c23c5c85975442c1a6c9e949@o4507248302292992.ingest.de.sentry.io/4507248459907152",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


store.dispatch("auth/attempt", localStorage.getItem('token')).then(() => {
  store.dispatch("global/index").then(() => {
    new Vue({
      el: '#app',
      render: h => h(App),
      router,
      store
    })
  });
});


