import DashboardLayout from "@/pages/layouts/DashboardLayout";

let classificationEsRoutes = {
  path: '/classifications',
  component: DashboardLayout,
  redirect: '/classifications/list',
  children: [
    {
      path: 'list/es',
      name: 'Spanish Classification List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationList'),
      meta: {
        permissionsCodes: ['es/classifications/index', 'restricted_own/es/classifications/index'],
      }
    },
    {
      path: 'create/es',
      name: 'Add Spanish Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['es/classifications/create'],
      }
    },
    {
      path: 'edit/:id/es',
      name: 'Edit Spanish Classification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/classifications/ClassificationForm'),
      meta: {
        permissionsCodes: ['es/classifications/update', 'restricted_own/es/classifications/update'],
      }
    }
  ]
}

export default classificationEsRoutes;
