var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),(_vm.$store.getters['auth/haveOneOfPermissions'](['notifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Notifications', path: '/notifications/list', icon: 'nc-icon nc-notification-70'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                (['ar/cases/index','restricted_own/ar/cases/index',
                                'en/cases/index','restricted_own/en/cases/index',
                                'fr/cases/index','restricted_own/fr/cases/index',
                                'es/cases/index','restricted_own/es/cases/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Cases', icon: 'fa fa-folder'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/cases/index','restricted_own/ar/cases/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic', path: '/cases/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/cases/index','restricted_own/en/cases/index']))?_c('sidebar-item',{attrs:{"link":{name: 'English', path: '/cases/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/cases/index','restricted_own/fr/cases/index']))?_c('sidebar-item',{attrs:{"link":{name: 'French', path: '/cases/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/cases/index','restricted_own/es/cases/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish', path: '/cases/list/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                (['ar/live-news/index','restricted_own/ar/live-news/index',
                                'en/live-news/index','restricted_own/en/live-news/index',
                                'fr/live-news/index','restricted_own/fr/live-news/index',
                                'es/live-news/index','restricted_own/es/live-news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Live News', icon: 'nc-icon nc-button-play'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/live-news/index','restricted_own/ar/live-news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic', path: '/live-news/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/live-news/index','restricted_own/en/live-news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'English', path: '/live-news/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/live-news/index','restricted_own/fr/live-news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'French', path: '/live-news/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/live-news/index','restricted_own/es/live-news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish', path: '/live-news/list/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/categories/index', 'restricted_own/ar/categories/index',
                                          'ar/tags/index', 'restricted_own/ar/tags/index',
                                          'ar/classifications/index', 'restricted_own/ar/classifications/index',
                                          'ar/authors/index', 'restricted_own/ar/authors/index',
                                          'ar/sources/index', 'restricted_own/ar/sources/index',
                                          'ar/news/index', 'restricted_own/ar/news/index' ]))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic News', icon: 'nc-icon nc-paper-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/news/index','restricted_own/ar/news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', path: '/news-ar/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/categories/index','restricted_own/ar/categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Categories', path: '/categories/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/classifications/index','restricted_own/ar/classifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Classifications', path: '/classifications/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/tags/index','restricted_own/ar/tags/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Tags', path: '/tags/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/authors/index','restricted_own/ar/authors/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Authors', path: '/authors/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/sources/index','restricted_own/ar/sources/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Sources', path: '/sources/list/ar'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['en/categories/index', 'restricted_own/en/categories/index',
                                          'en/tags/index', 'restricted_own/en/tags/index',
                                          'en/classifications/index', 'restricted_own/en/classifications/index',
                                          'en/authors/index', 'restricted_own/en/authors/index',
                                          'en/sources/index', 'restricted_own/en/sources/index',
                                          'en/news/index', 'restricted_own/en/news/index' ]))?_c('sidebar-item',{attrs:{"link":{name: 'English News', icon: 'nc-icon nc-paper-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['en/news/index','restricted_own/en/news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', path: '/news-en/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/categories/index','restricted_own/en/categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Categories', path: '/categories/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/classifications/index','restricted_own/en/classifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Classifications', path: '/classifications/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/tags/index','restricted_own/en/tags/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Tags', path: '/tags/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/authors/index','restricted_own/en/authors/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Authors', path: '/authors/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/sources/index','restricted_own/en/sources/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Sources', path: '/sources/list/en'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['fr/categories/index', 'restricted_own/fr/categories/index',
                                          'fr/tags/index', 'restricted_own/fr/tags/index',
                                          'fr/classifications/index', 'restricted_own/fr/classifications/index',
                                          'fr/authors/index', 'restricted_own/fr/authors/index',
                                          'fr/sources/index', 'restricted_own/fr/sources/index',
                                          'fr/news/index', 'restricted_own/fr/news/index' ]))?_c('sidebar-item',{attrs:{"link":{name: 'French News', icon: 'nc-icon nc-paper-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/news/index','restricted_own/fr/news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', path: '/news-fr/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/categories/index','restricted_own/fr/categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Categories', path: '/categories/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/classifications/index','restricted_own/fr/classifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Classifications', path: '/classifications/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/tags/index','restricted_own/fr/tags/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Tags', path: '/tags/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/authors/index','restricted_own/fr/authors/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Authors', path: '/authors/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/sources/index','restricted_own/fr/sources/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Sources', path: '/sources/list/fr'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['es/categories/index', 'restricted_own/es/categories/index',
                                          'es/tags/index', 'restricted_own/es/tags/index',
                                          'es/classifications/index', 'restricted_own/es/classifications/index',
                                          'es/authors/index', 'restricted_own/es/authors/index',
                                          'es/sources/index', 'restricted_own/es/sources/index',
                                          'es/news/index', 'restricted_own/es/news/index' ]))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish News', icon: 'nc-icon nc-paper-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['es/news/index','restricted_own/es/news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', path: '/news-es/list/es'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/categories/index','restricted_own/es/categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Categories', path: '/categories/list/es'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/classifications/index','restricted_own/es/classifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Classifications', path: '/classifications/list/es'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/tags/index','restricted_own/es/tags/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Tags', path: '/tags/list/es'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/authors/index','restricted_own/es/authors/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Authors', path: '/authors/list/es'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/sources/index','restricted_own/es/sources/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Sources', path: '/sources/list/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/polls/index','restricted_own/ar/polls/index',
                                'en/polls/index','restricted_own/en/polls/index',
                                'fr/polls/index','restricted_own/fr/polls/index',
                                'es/polls/index','restricted_own/es/polls/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Polls', icon: 'nc-icon nc-notes'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/polls/index','restricted_own/ar/polls/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic', path: '/polls/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/polls/index','restricted_own/en/polls/index']))?_c('sidebar-item',{attrs:{"link":{name: 'English', path: '/polls/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/polls/index','restricted_own/fr/polls/index']))?_c('sidebar-item',{attrs:{"link":{name: 'French', path: '/polls/list/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/polls/index','restricted_own/es/polls/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish', path: '/polls/list/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/visual-editor/save-content','en/visual-editor/save-content','fr/visual-editor/save-content','es/visual-editor/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Visual Editors', icon: 'nc-icon nc-settings-90'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/visual-editor/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic Home', path: '/visual-editors/build/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/visual-editor/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'English Home', path: '/visual-editors/build/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/visual-editor/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'French Home', path: '/visual-editors/build/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/visual-editor/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish Home', path: '/visual-editors/build/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/live-coverage/save-content','en/live-coverage/save-content','fr/live-coverage/save-content','es/live-coverage/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Live Coverage', icon: 'nc-icon nc-button-play'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/live-coverage/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic Home', path: '/visual-editors/live-coverage/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/live-coverage/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'English Home', path: '/visual-editors/live-coverage/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/live-coverage/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'French Home', path: '/visual-editors/live-coverage/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/live-coverage/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish Home', path: '/visual-editors/live-coverage/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/campaign/save-content','en/campaign/save-content','fr/campaign/save-content','es/campaign/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'campaign', icon: 'nc-icon nc-notification-70'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/campaign/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic Home', path: '/visual-editors/campaign/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/campaign/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'English Home', path: '/visual-editors/campaign/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/campaign/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'French Home', path: '/visual-editors/campaign/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/campaign/save-content']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish Home', path: '/visual-editors/campaign/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['/visual-editors/menus/es','fr/menus/index','es/menus/index','en/menus/index','ar/menus/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Menus ', icon: 'nc-icon nc-grid-45'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/menus/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Arabic Menus', path: '/visual-editors/menus/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/menus/index']))?_c('sidebar-item',{attrs:{"link":{name: 'English Menus', path: '/visual-editors/menus/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['fr/menus/index']))?_c('sidebar-item',{attrs:{"link":{name: 'French Menus', path: '/visual-editors/menus/fr'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['es/menus/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Spanish Menus', path: '/visual-editors/menus/es'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['electoral-districts/index','governorates/index',
                     'departments/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Lebanon Results', icon: 'nc-icon nc-chart-bar-32'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['governorates/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Governorates', path: '/governorates/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['departments/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Electoral Districts', path: '/electoral-districts/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['electoral-districts/index']))?_c('sidebar-item',{attrs:{"link":{name: 'الدائرة الصغرى', path: '/kadaa/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['continents/index','countries/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Election Results', icon: 'nc-icon nc-chart-bar-32'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['continents/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Continents', path: '/continents/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['countries/index']))?_c('sidebar-item',{attrs:{"link":{name: 'countries', path: '/countries/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['contact-us/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['newsletter/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['job-requests/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Job Requests ', icon: 'nc-icon  nc-badge', path: '/job-requests'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['pages/index',
                                           'content-pages/create',
                                           'redirection-pages/create',
                                           'branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['pages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['panel-groups/index','panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['mobile-settings/get','settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-settings-gear-64'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Website Settings', path: '/settings/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['mobile-settings/get']))?_c('sidebar-item',{attrs:{"link":{name: 'Mobile Settings', path: '/mobile-settings/get'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                          // || $store.getters['auth/checkAccess']('permissions/index')
                          || _vm.$store.getters['auth/checkAccess']('users/index')
                          || _vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Deleted Users', path: '/users/DeletedUsersList'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }